import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "article-menu-content";

export class ArticleMenuContent extends HTMLElement {

    _ele;
    _data;

    constructor(data) {
        super();
        this._data = data;
    }

    connectedCallback() {

        this._ele = (
            <li class="page-nav-level-0__li">
                <a id={this._data.id} class="page-nav-link" {... this._data.url ? {href: this._data.url} : {}}>{this._data.name}</a>
                <div class="page-nav-level page-nav-level-1">
                    <a class="page-nav-level__back-btn">Back</a>
                    { this._data.header && <a class="page-nav-level__nav-header" href={this._data.header.url} innerHTML={this._data.header.name} /> }
                    <ul class="page-nav-level-1__ul--type-2">
                        {this._data.children.map(d =>
                            <li
                                id={d.id}
                                class={ d.article == true ? "page-nav-level-1__li--type-2" : "page-nav-level-1__li--type-2 page-nav-level-1__li--type-2-" + d.id }
                            >
                                <a href={d.url}>
                                    <div class={ d.article == true ? "nav-" + d.id + "-block nav-" + d.id + "-block--article" : "nav-" + d.id + "-block" }>
                                        {d.image && (
                                            <div class={ "nav-" + d.id + "-block__img-container"}>
                                                <img src={d.image} />
                                            </div>
                                        )}
                                        <div class={ "nav-" + d.id + "-block__text-block" }>
                                            <span class={ "nav-" + d.id + "-block__text-title" }>{d.name}</span>
                                            <span class={ "nav-" + d.id + "-block__text-content" }>{d.description}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        );

        this.classList.add("page-nav-level-0__li");
        this.appendChild(this._ele);
    }
}

customElements.define(TAG, ArticleMenuContent);
