module.exports = (function() {
  "use strict";
  var SWITCH_ACCOUNT_API =
    "/account/switch-current-account-to/";
  //var USER_PROFILE_COOKIE_API = "/frontend-service/user-profile/";
  var KEY_USER_TEMPLATE_CONTEXT_DATA = "reece-user-template-context-data";
  var init = function() {
    var containerMobile = document.querySelector(
      '[data-reece-mustache-template-id="mobile-user-nav"]'
    );
    var container = document.querySelector(
      '[data-reece-mustache-template-id="user-nav"]'
    );
    container.addEventListener("click", e => switchAccountHandler(e, e.target));
    containerMobile.addEventListener("click", e =>
      switchAccountHandler(e, e.target)
    );
  };
  function switchAccountHandler(e, target) {
    if (!e || !target) {
      return;
    }
    if (target.dataset.customerNumber) {
      e.preventDefault();
      switchAccount(target, target.dataset.customerNumber);
    } else {
      if (target.parentElement !== target) {
        return switchAccountHandler(e, target.parentElement);
      }
    }
  }
  function switchAccount(target, customerNumber) {
    var setAccountCookieURL = SWITCH_ACCOUNT_API + customerNumber;
    var setCookiePromise = fetch(setAccountCookieURL, {
      credentials: "include"
    });
    setCookiePromise.then(function() {
      window.sessionStorage.removeItem(KEY_USER_TEMPLATE_CONTEXT_DATA);
      window.location.href = "/max/";
    });
  }
  return {
    init: init
  };
})();
