import "./header-value-proposition.scss";
import Swiper from "swiper/dist/js/swiper.min.js"

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-header-value-proposition";

export class HeaderValueProposition extends HTMLElement {

    _ele;
    _data;
    _valuePropositionSlider;
    _breakpoint;

    constructor(data) {
        super();
        this._data = data;
    }

    connectedCallback() {
        this._ele = (
            <div class="page-value-proposition-wrapper">
                <div id="value-propositions" class="value-proposition__list swiper-container">
                    <div class="swiper-wrapper">
                        {this._data.valuePropositions.map((valueProposition) => {
                            return (
                                <div class="value-proposition__list-item swiper-slide">{valueProposition.text}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );

        this.appendChild(this._ele);
        this._initSwiper();
    }

    _initSwiper() {
        this._valuePropositionSlider = void 0;
        this._breakpoint = window.matchMedia("(min-width: 992px)");

        // keep an eye on viewport size changes
        this._breakpoint.addListener(this._breakpointChecker());
        // kickstart
        this._breakpointChecker();
    }

    _breakpointChecker() {
        // For larger screen size
        if (this._breakpoint.matches === true) {
            // Disable slider
            if (this._valuePropositionSlider !== undefined)
                this._valuePropositionSlider.destroy(true, true);
        } else if (this._breakpoint.matches === false) {
            return this._enableSlider();
        }
    }

    _enableSlider() {
        this._valuePropositionSlider = new Swiper("#value-propositions", {
            loop: true,
            speed: 1000,
            slidesPerView: "auto",
            // centeredSlides: true,
            a11y: true,
            autoplay: {
                delay: 4000
            }
        });
    }
}

customElements.define(TAG, HeaderValueProposition);
