import { h } from "../../_common/jsx-dom.svg.js";
import { sendMaxEventV2 } from "../../scripts/max-analytics/index.js";

const TAG = "rg-header-navigation-mobile";

export class HeaderMobileNavigation extends HTMLElement {

    _ele;
    _links;

    constructor(links) {
        super();
        this._links = links;
    }

    connectedCallback() {
        this._ele = (
            <ul>
                {this._links.map((link) => {
                    let analyticFunction;
                    if (link.analytics) {
                        analyticFunction = () => {
                            sendMaxEventV2({
                                eventName: link.analytics.eventName,
                                eventData: link.analytics.eventData
                            });
                        }
                    }

                    return (
                        <li>
                            <a 
                                href={link.href} 
                                {...link.target ? {target: link.target} : {}}
                                {...link.analytics ? {onClick: analyticFunction} : {}}
                            >
                                {link.text}
                            </a>
                        </li>
                    )
                })}
            </ul>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderMobileNavigation);
