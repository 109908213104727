import {setCacheComponentHeaderConfig, getCacheComponentHeaderConfig} from "./cache-component-header-config";
import {fetchComponentHeaderConfig} from "./fetch-component-header-config";
import {reportError} from "../error-reporting";
import * as config from "../../../_config/header/config.header.data.au.default";

export async function getComponentHeaderConfig() {
    try {
        let config = getCacheComponentHeaderConfig();
        if (!config) {
            config = await fetchComponentHeaderConfig();
            setCacheComponentHeaderConfig(config);
        }
        return config;
    }
    catch(e) {
        reportError(e + "Component header config unavailable");
        return config.default;
    }
}