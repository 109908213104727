import { Anonymous } from "./anonymous/anonymous";
import { LoggedIn } from "./logged-in/logged-in";
import { h } from "../../_common/jsx-dom.svg.js";
import { sendMaxEventV2 } from "../../scripts/max-analytics/index.js";

const TAG = "rg-header-top-user";

export class HeaderTopUser extends HTMLElement {

    _ele;
    _locale;
    _data;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        let headerUser;
        this._userData && this._userData.isAnonymousUser === false ? headerUser = new LoggedIn(this._userData, this._data) : headerUser = new Anonymous();

        this._ele = (
            <nav data-reece-mustache-template-id="user-nav">
                <ul class="header-top-right-nav__ul">
                    {this._data.userLinks.map((link) => {
                        let analyticFunction;
                        if (link.analytics) {
                            analyticFunction = () => {
                                sendMaxEventV2({
                                    eventName: link.analytics.eventName,
                                    eventData: link.analytics.eventData
                                });
                            }
                        }

                        return (
                            <li class="header-top-right-nav__li">
                                <a 
                                    class={link.linkClass} 
                                    id={link.id} 
                                    href={link.href}
                                    {...link.analytics ? {onClick: analyticFunction} : {}}
                                >
                                    {link.text}
                                </a>
                            </li>
                        )
                    })}
                    {headerUser}
                </ul>
            </nav>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderTopUser);
