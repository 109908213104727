export class Accounts {

    _userData;

    constructor(userData) {
        this._userData = userData;
    }
    
    transform(){
        let accountChangeOptions = new AccountChangeOptions();
        accountChangeOptions.activeAccount = this._userData.activeAccount ? this._userData.activeAccount : null;
        accountChangeOptions.showAccountChangeLink = this._userData.accountChangeOptions && this._userData.accountChangeOptions.showAccountChangeLink;
        accountChangeOptions.showAccountChangeList = this._userData.accountChangeOptions && this._userData.accountChangeOptions.showAccountChangeList;

        if (this._userData.accountChangeOptions && this._userData.accountChangeOptions.accountChangeListOptions) {
            accountChangeOptions.accountChangeListOptions =   this._userData.accountChangeOptions.accountChangeListOptions.map((accountChangeListOption)=>{
                let accountChangeListOptionInstance = new AccountChangeListOption();
                accountChangeListOptionInstance.customerNumber = accountChangeListOption.customerNumber;
                accountChangeListOptionInstance.customerName = accountChangeListOption.customerName;
                accountChangeListOptionInstance.isParentAccount = accountChangeListOption.isParentAccount;
                accountChangeListOptionInstance.isChildAccount = accountChangeListOption.isChildAccount;
                accountChangeListOptionInstance.accountTypeStyleClass = accountChangeListOption.isParentAccount ? 'group-parent-account' : accountChangeListOption.isChildAccount ? 'group-child-account' : '';
                accountChangeListOptionInstance.activeStyleClass = accountChangeListOption.isActive ? 'active' : '';
                return accountChangeListOptionInstance;
            })
        } else {
            accountChangeOptions.accountChangeListOptions = [];
        }
        return accountChangeOptions;
    }
}

class AccountChangeOptions {
    showAccountChangeList;
    accountChangeListOptions;
    showAccountChangeLink;
    activeAccount;
}

class AccountChangeListOption {
    accountTypeStyleClass;
    activeStyleClass;
    isParentAccount;
    isChildAccount;
    customerNumber;
    customerName;
}
