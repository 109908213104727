import { h } from "../../_common/jsx-dom.svg.js";

import { MobileLoggedIn } from "./logged-in/logged-in";
import { MobileAccounts } from "./accounts/accounts";

const TAG = "rg-header-navigation-mobile-user";

export class HeaderNavigationMobileUser extends HTMLElement {

    _ele;
    _data;
    _userData;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        let accounts;

        if (this._userData && this._userData.isMaxUser){
            accounts = new MobileAccounts(this._userData);
        }

        this._ele = (
            <div class="page-nav-top__group" data-reece-mustache-template-id="mobile-user-nav">
                <button id="page-nav-close" class="page-nav-top__close-btn"> </button>
                <div class="page-nav-top__link-group">
                    <ul class="page-nav-top__link-group-ul">
                        <li class="page-nav-top__link-group-li">
                            <a class="page-nav-top__link page-nav-top__icon--store" id="Storefinder" href="/storefinder">Store Finder</a>
                        </li>
                        {this._userData && this._userData.isAnonymousUser === false && (
                            <li class="page-nav-top__link-group-li">
                                {this._createMobileLoggedIn(this._userData, this._data)}
                            </li>
                        )}
                        {this._userData && this._userData.isAnonymousUser === true && ([
                            <li class="page-nav-top__link-group-li">
                                <a class="page-nav-top__link page-nav-top__icon--login" id="Login" href="/account/login">Login</a>
                            </li>
                        ])}
                        {this._userData && this._userData.isMaxUser && (
                            <li class="page-nav-top__link-group-li">
                                {accounts}
                            </li>
                        )}

                    </ul>
                </div>
            </div>
        );

        this.appendChild(this._ele);
    }

    _createMobileLoggedIn(userData, data) {
        return new MobileLoggedIn(userData, data);
    }
}

customElements.define(TAG, HeaderNavigationMobileUser);
