import { reportError } from "../scripts/error-reporting";

export class MegaMenuData {

    parseSiteMenu(data, userData) {

        data.forEach(m => {
            try {
                m.data = m.data || {};
                this._updateLvl1MenuTypeBoolFlags(m);
                this._updateMenuNameAndId(m);
                this._updateLvl1MenuLinkUrls(m);
                this._updateMenuForPermissionAndVisibility(m, userData);
            } catch (e) {
                reportError(e);
            }
        });

        try {
            this._updateBoldAndLast(data);
        } catch (e) {
            reportError(e);
        }


        return data;
    }

    _updateLvl1MenuTypeBoolFlags(rawMenu: MenuItemLevel1) {
        const m = ((rawMenu: any): MenuItemLevel1Enhanced);
        m.isMegaMenu = m.type === "mega";
        m.isToolMenu = m.type === "tools";
        m.isContentMenu = m.type === "content";
        m.isArticleMenu = m.type === "article";
    }

    _updateLvl1MenuLinkUrls(rawMenu: MenuItemLevel1) {
        const m = ((rawMenu.data: any): MenuItemEnhanced);
        m.urlAttribute = m.url ? "href" : null;
    }

    _updateBoldAndLast(menuDataArray: MenuItemLevel1[]) {
        menuDataArray.forEach(
            m =>
                m.data &&
                (((m.data: any): MenuItemEnhanced).bold = m.type === "mega")
    );
        const boldMenu = menuDataArray.filter(
            m => m.data && ((m.data: any): MenuItemEnhanced).bold === true
    );
        if (boldMenu.length > 0) {
            const lastBoldMenu = boldMenu[boldMenu.length - 1];
            if (lastBoldMenu.data)
                ((lastBoldMenu.data: any): MenuItemEnhanced).boldAndLast = true;
        }
    }

    _updateMenuForPermissionAndVisibility(
        menu: MenuItemLevel1,
        userData: ?UserData
    ) {
        if (menu.data && menu.data.children) {
            menu.data.children.forEach(itemRaw => {
                const item = ((itemRaw: any): MenuItemEnhanced);
                if (item.permission) {
                    const hasPermission: boolean = this._parsePermission(
                        item.permission
                    ).some(p => this._getValue(userData, p) === true);
                    item.disabled = hasPermission === false;
                }
                if (item.visible === undefined) {
                    item.visible = true;
                } else if (typeof item.visible === "string") {
                    item.visible = this._getValue(userData, item.visible) !== false;
                }
            });
        }
    }

    _parsePermission(permissionsInORlogic) {
        return permissionsInORlogic
            .split("||")
            .map(c => c.trim())
            .filter(c => c);
    }

    _normalizeTitle(title: string) {
        return title
            .replace(/\s/g, "-")
            .replace(/[^0-9a-zA-Z_-]/g, "")
            .split(" ")
            .join("-")
            .toLocaleLowerCase();
    }

    _updateMenuNameAndId(rawMenu: MenuItemLevel1) {
        //update lvl1 menu id
        const m = ((rawMenu: any): MenuItemLevel1Enhanced);
        m.data.name = m.hasOwnProperty('data') && m.data.hasOwnProperty('name') ? m.data.name : rawMenu.title;
        m.data.id = m.data.id || `menu-${this._normalizeTitle(m.title)}`;
        //update sub menu id
        this._updateSubMenuId(m.data.children, m.type, m.data);
    }

    _updateSubMenuId(
        menus: ?(MenuItem[]),
        menuType: MenuType,
        lvl1Menu: MenuItem
    ) {
        if (menus) {
            menus.forEach(cRaw => {
                const c = ((cRaw: any): MenuItemEnhanced);
                const lvl1MenuNameEncoded = this._normalizeTitle(lvl1Menu.name);
                if (menuType === "mega") {
                    const idValue = this._grabLastId(c.url);
                    c.idAttribute = idValue ? "id" : null;
                    c.lvl1MenuNameEncoded = lvl1MenuNameEncoded;
                    c.idStem = idValue;
                } else {
                    c.idAttribute = "id";
                    if (!c.id) {
                        const menuTitleEncoded = this._normalizeTitle(c.name);
                        c.id = `menu-${lvl1MenuNameEncoded}-${menuTitleEncoded}`;
                    }
                }
                if (c.children) {
                    this._updateSubMenuId(c.children, menuType, lvl1Menu);
                }
            });
        }
    }

    _grabLastId(url) {
        if (!url) return null;

        const idxIndustry = url.indexOf("industryFacets=");
        const industryFacets =
            idxIndustry === -1
                ? null
                : url.substring(idxIndustry + "industryFacets=".length);

        const questionIndex = url.indexOf("?");
        if (questionIndex !== -1) {
            url = url.substring(0, questionIndex);
        }
        const idx = url.lastIndexOf("-c");

        if (idx === -1) {
            return industryFacets || null;
        } else {
            return "c" + url.substring(idx + 2);
        }
    }

    _getValue(aObj: any, propsInString: string) {
        return propsInString.split(".").reduce((acc, prop) => {
            if (acc === undefined) return acc;
            if (acc === null) return undefined;
            return acc[prop];
        }, aObj);
    }

}