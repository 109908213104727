import jsCookie from "js-cookie";

export default (function() {
    "use strict";

    const TOKEN_EXPIRY_COOKIE_NAME = "_rg.token_exp";
    const REFRESH_TOKEN_API = "/account/login/oauth2/refresh/";
    const EXPIRY_LEEWAY_MS = 5 * 60 * 1000; // 5 minutes leeway before expiry

    let timer, retries = 0, fetchInProgress = false, hasTokenExpiryCookie = false
    const MAX_RETRIES = 2

    var init = function() {
        checkTokenPeriodically();
    };

    function checkTokenPeriodically() {
        if (timer) clearTimeout(timer);
    
        var tokenExpiry = jsCookie.get(TOKEN_EXPIRY_COOKIE_NAME) ;

        // skip for non logged-in user
        if (!tokenExpiry) {
            // The token expiry cookie must have been removed when logged out from a different tab.
            if (hasTokenExpiryCookie) {
                redirectToLoginPage();
            }
            return
        }

        hasTokenExpiryCookie = true;

        var now = new Date().getTime();
        var expiredOn = tokenExpiry * 1000

        if (expiredOn - now <= EXPIRY_LEEWAY_MS) {
            refreshToken()
        } 
           
        // periodically 
        timer = setTimeout(() => checkTokenPeriodically(), 1000);
    }
    
    async function refreshToken() {
        if (fetchInProgress) {
            return
        }

        try {
            fetchInProgress = true;

            const response = await fetch(REFRESH_TOKEN_API, { method: 'POST' });
            if (response.ok) {
                retries = 0; 
                fetchInProgress = false;
            } else if (response.status == 400) {
                fetchInProgress = false;
                redirectToLoginPage(); // permission denied, probably token expired
            } else {
                // Handle errors, retry
                console.error("Failed to refresh token", response.status, response.statusText);
                fetchInProgress = false;
                retryRefreshToken();
            } 
        } catch (err) {
            // Handle network errors, retry
            console.error("Failed to refresh token", err);
            fetchInProgress = false;
            retryRefreshToken();
        }  
    }

    function retryRefreshToken() {
        if (retries++ >= MAX_RETRIES) {
            // exceed max retries to refresh token, redirect to login page
            redirectToLoginPage();
            return;
        }

        refreshToken();
    }

    function redirectToLoginPage() {
        // skip redirect if already on /account login/logout/refresh
        if (window.location.href.indexOf("\\/account\\/") > -1) return

        const currentUrl = window.location.href;
        window.location.href=`/account/login?resumePath=${encodeURIComponent(currentUrl)}`
    }

    return {
      init: init
    };
  })();
  