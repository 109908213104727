import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "mobile-rg-header-logged-in";

export class MobileLoggedIn extends HTMLElement {

    _ele;
    _data;
    _userData;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        this._ele = (
                <li class="page-nav-top__link-group-li">
                    <a class="page-nav-top__link page-nav-top__icon--login" id="LoggedInUserName">{this._userData.firstName}</a>
                    <div class="page-nav-top__link-sub-group">
                        {this._userData.isMaxUser && (
                            <a href="/max" class="page-nav-top__link-sub" id="AccountHome">Account home</a>
                        )}
                        {!this._userData.isMaxUser && this._data.retailUserLinks.map((link) => {
                            return (
                                <a href={link.href} id={link.id} class="page-nav-top__link-sub">{link.text}</a>
                            )
                        })}
                        <a href="/max/user-profile/" class="page-nav-top__link-sub" id="ManageAccount">Account settings</a>
                        <a href="/account/logout" class="page-nav-top__link-sub" id="Logout">Logout</a>
                    </div>
                </li>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, MobileLoggedIn);
