import {IconMenuContentChildElement} from "./icon-menu-content-child-element";

import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "icon-menu-content";

export class IconMenuContent extends HTMLElement {

    _ele;
    _data;
    _theme;

    constructor(data, theme) {
        super();
        this._data = data;
        this._theme = theme;
    }

    connectedCallback() {

        let menuItems = this.createItems(this._data, this._theme);

        this._ele = (
            <li class="page-nav-level-0__li">
                <a id={this._data.id} class="page-nav-link" innerHTML={this._data.name} {... this._data.url ? { href: this._data.url } : {}} />
                {this._data.children && (
                    <div class="page-nav-level page-nav-level-1">
                        <a class="page-nav-level__back-btn">Back</a>
                        { this._data.header && <a class="page-nav-level__nav-header" href={this._data.header.url} innerHTML={this._data.header.name} /> }
                        {menuItems}
                    </div>
                )}
            </li>
        );

        this.classList.add("page-nav-level-0__li");
        this.appendChild(this._ele);
    }

    createItems(data, theme) {
        let menuItems = document.createElement('ul');
        menuItems.classList.add("page-nav-level-1__ul--type-3");

        if (data.children) {
            data.children.map(c => {
                let m = new IconMenuContentChildElement(c, theme);
                menuItems.appendChild(m);
            });
        }

        return menuItems;
    }
}

customElements.define(TAG, IconMenuContent);
