import { h } from "../../../_common/jsx-dom.svg.js";
import { sendPublicMaxEvent } from "../../../scripts/max-analytics";
import { getCacheComponentHeaderConfig } from "../../../scripts/component-header-config/cache-component-header-config"

const TAG = "rg-header-anonymous";

export class Anonymous extends HTMLElement {

    _ele;

    constructor() {
        super();
    }

    connectedCallback() {
        this._ele = (
            <span>
                <li class="header-top-right-nav__li">
                    <a class="header-top-right-nav__link header-top-right-nav__icon--login" id="Login" href="/account/login" onClick={(event) => {
                        event.preventDefault()
                        const href = event.currentTarget.getAttribute('href')
                        sendPublicMaxEvent("viewLoginSignup", { "source": "WEB", "brand": this.getBrand() })
                        setTimeout(() => (window.location.href = href), 300)
                    }}>Login</a>
                </li>
            </span>
        );

        this.appendChild(this._ele);
    }

    getBrand() {
        const headerConfig = getCacheComponentHeaderConfig();
        return headerConfig ? headerConfig.theme.toUpperCase() : null
    }
}

customElements.define(TAG, Anonymous);
