import "./header-navigation.scss";
import { HeaderNavigationMobileUser } from "../mobile-user-navigation/mobile-user-navigation";
import { HeaderMegaMenu } from "../../mega-menu/mega-menu";
import { HeaderMobileNavigation } from "../mobile-navigation/mobile-navigation";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-header-navigation";

export class HeaderNavigation extends HTMLElement {

    _ele;
    _data;
    _userData;
    _theme;

    constructor(userData, data, theme) {
        super();
        this._userData = userData;
        this._data = data;
        this._theme = theme;
    }

    connectedCallback() {

        const mobileUserNavigation = new HeaderNavigationMobileUser(this._userData, this._data);
        const megaMenu = new HeaderMegaMenu(this._userData, this._theme);
        const mobileNavigation = new HeaderMobileNavigation(this._data.mobileLinks);

        this._ele = (
            <div id="page-nav" class="page-nav-wrapper">
                <nav class="page-nav-top">
                    {mobileUserNavigation}
                </nav>
                <nav class="page-nav-main">
                    {megaMenu}
                </nav>
                <nav class="page-nav-btm">
                    {mobileNavigation}
                </nav>
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderNavigation);
