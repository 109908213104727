export default (function () {
  "use strict"

  const MAX_NOTIFICATION_CENTER_WIDGET =
    "/max/user-notification/dist/max-user-notification.nocache.js"

  var init = function (userData) {
    if (_shouldShowMaxUserNotificationCenter(userData))
      _loadMaxUserNotificationCenter()
  }

  function _shouldShowMaxUserNotificationCenter(userData) {
    return userData.isMaxUser && !userData.isUnlinkedTradeUser
  }

  function _loadMaxUserNotificationCenter() {
    // load the notification center widget script
    let maxNotificationCenterWebComponent = document.createElement("script")
    let headEl = document.getElementsByTagName("head")[0]
    maxNotificationCenterWebComponent.src = MAX_NOTIFICATION_CENTER_WIDGET
    headEl.appendChild(maxNotificationCenterWebComponent)
  }

  return {
    init: init,
  }
})()
