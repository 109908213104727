import "./header-main.scss";
import * as wishlist from "./../../scripts/wishlist/wishlist.js";
import { sendMaxEvent } from "../../scripts/max-analytics";
import {reportError} from "../../scripts/error-reporting";
import {h} from "../../_common/jsx-dom.svg.js";

/* eslint-disable */
const TAG = "rg-header-main";

export class HeaderMain extends HTMLElement {

    _ele;
    _features;
    _userData;
    _logo;
    _props;
    _domain;

    constructor(userData, features, logo, props) {
        super();
        this._userData = userData;
        this._features = features;
        this._logo = logo;
        this._props = props;
        this._domain = this.getBaseDomain()
    }

    connectedCallback() {
        this._ele = (
            <div class="page-header-main-wrapper">
                <div class="page-header__main">
                    <button id="page-nav-open" class="header-main__icon--menu"></button>
                    <div class="logo-wrapper">
                        <a href="/">
                            <img class="logo--header" src={this._logo.src} alt={this._logo.alt}/>
                        </a>
                    </div>
                    <search-bar debounce='300' trigger-length='3' data-url='/search/?query=##search##'></search-bar>
                    {this._userData.isMaxUser &&
                    (this._props.showPunchoutProductList || this._props.showPunchoutBranchQuotes) && (
                        <div class="mobileHeaderButtonsContainer">
                            {this._props.showPunchoutProductList && (
                                this.getMobilePunchoutButton("/max/productlists/?punchout=true",
                                    "icon-reece header-main__image_icon header-main__product_list_icon",
                                    "Product",
                                    "Lists", "clickMobilePunchOutProductList")
                            )}
                            {this._props.showPunchoutBranchQuotes && (
                                this.getMobilePunchoutButton("/max/branchquotes/?punchout=true",
                                    "icon-reece header-main__image_icon header-main__branch_quote_icon",
                                    "Branch",
                                    "Quotes", "clickMobilePunchOutBranchQuotes")
                            )}
                        </div>
                    )}
                    <div class="header-main__icons" data-reece-mustache-template-id="cart-nav"
                         data-reece-mustache-template-init-vis-hidden-flex>
                        {this._userData.isMaxUser ? (
                            <span>
                                {this._props.showPunchoutProductList && (
                                    this.getDesktopPunchoutButton("/max/productlists/?punchout=true",
                                        "icon-reece header-main__image_icon header-main__product_list_icon",
                                        "Product",
                                        "Lists", "clickDesktopPunchOutProductList")
                                )}
                                {this._props.showPunchoutBranchQuotes && (
                                    this.getDesktopPunchoutButton("/max/branchquotes/?punchout=true",
                                        "icon-reece header-main__image_icon header-main__branch_quote_icon",
                                        "Branch",
                                        "Quotes", "clickDesktopPunchOutBranchQuotes")
                                )}
                                {this._props.showNotificationCenter && (
                                    <div id="notification-center-placeholder" className="header-main__notification-center-placeholder">
                                    </div>)}
                                {this._props.showMaxCart && this.renderMaxCart()}
                                {this._features.wishlist && (
                                    <a href="/wishlist" class="header-main__icon--fav header-main__wishlist"
                                       style="visibility: hidden">
                                        <span>Wishlist</span>
                                        <div id="retail-wishlist-count-holder" class="wishlist-count">&nbsp;</div>
                                    </a>
                                )}
                            </span>
                        ) : (
                            <span>
                                {this._features.retailCart && (
                                    <a id="cart-icon" href="/bathrooms/cart/" class="header-main__icon--cart">
                                        <span>My cart</span>
                                        <div id="cart-count-holder" class="cart-count">&nbsp;</div>
                                    </a>
                                )}
                                {this._features.wishlist && (
                                    <a href="/wishlist" class="header-main__icon--fav header-main__wishlist">
                                        <span>Wishlist</span>
                                        <div id="retail-wishlist-count-holder" class="wishlist-count">&nbsp;</div>
                                    </a>
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );

        this.appendChild(this._ele);
        this.initWishlist();
    }

    initWishlist() {

        if (this._features.wishlist) {
            window.reece = window.reece || {};
            try {
                wishlist.init();
            } catch (e) {
                reportError(e);
            }
        }
    }

    renderMaxCart() {
        return (
            <a id="cart-icon" data-domain={this._domain} className="header-main__max--icon--cart">
                <div id="cart-count-holder" className="header-main__max--icon--cart__count">&nbsp;</div>
                <div className="header-main__max--icon--cart__icon--wrapper">
                    <span data-domain={this._domain} className={"header-main__max--icon--cart__icon"} id={"max-cart-icon-svg"}></span>
                    <span>My cart</span>
                </div>
            </a>)
    }

    getBaseDomain() {
        var ACTROL = "actrol"
        var METALFLEX = "metalflex"
        var REECE = "reece"
        var domain = window.location.hostname
        if(domain.includes(ACTROL)) {
            return ACTROL
        } else if(domain.includes(METALFLEX)) {
            return  METALFLEX
        } else {
            return REECE
        }
    }

    getMobilePunchoutButton(href, claz, iconText1, iconText2, eventName) {
        return <section>
            <div>
                <a id="punchout-product-list-icon-2"
                   class="mobileHeaderButtonsContainer__punchout"
                   onClick={pushEventAndNavigateTo.bind(this, eventName, href)}
                   href={href}>
                    <div class="mobileHeaderButtonsContainer__punchout-row">
                        <div class="mobileHeaderButtonsContainer__punchout-column">
                            <i class={claz}></i>
                        </div>
                        &nbsp;
                        <div class="mobileHeaderButtonsContainer__punchout-column">
                            {iconText1}
                            <span>{iconText2}</span>
                        </div>
                    </div>
                </a>
            </div>
        </section>
    }

    getDesktopPunchoutButton(href, claz, titleText1, titleText2, eventName) {
        return <a id="punchout-product-list-icon"
                  className="header-main__link--punchout"
                  onClick={pushEventAndNavigateTo.bind(this, eventName, href)}
                  href="/max/productlists/?punchout=true">
            <div class="header-main__link--punchout-row">
                <div class="header-main__link--punchout-column">
                    <i class={claz}></i>
                </div>
                &nbsp;
                <div class="header-main__link--punchout-column">
                    <span>{titleText1}</span>
                    <span>{titleText2}</span>
                </div>
            </div>
        </a>
    }

}

function pushEventAndNavigateTo(eventName, href, clickEvt) {
  clickEvt.preventDefault();
  sendMaxEvent(eventName);
  setTimeout(() => (window.location.href = href), 300);
}

customElements.define(TAG, HeaderMain);
