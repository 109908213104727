import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "mega-menu-content";

export class MegaMenuContent extends HTMLElement {

    _ele;
    _data;
    _theme;

    constructor(data, theme) {
        super();
        this._data = data;
        this._theme = theme;
    }

    connectedCallback() {

        this._ele = (
            <li class={this._data.boldAndLast ? "page-nav-level-0__li page-nav-level-0__li--bold page-nav-level-0__li--bold-last" : "page-nav-level-0__li page-nav-level-0__li--bold"}>
                <a id={this._data.id} class="page-nav-link" {... this._data.url ? {href: this._data.url} : {}}>{this._data.name}</a>
                <div class="page-nav-level page-nav-level-1">
                    <a class="page-nav-level__back-btn">Back</a>
                    <ul class="page-nav-level-1__ul--type-1">
                        {this._data.children.map(level1 =>
                            <li class="page-nav-level-1__li--type-1">
                                <a class={`page-nav-link page-nav-level-1__heading--type-1 ${(level1.children && level1.children.length > 0) && 'has-children'}`} id={"menu-"+level1.lvl1MenuNameEncoded+"-lvl1-"+level1.idStem}>
                                    <i class={"icon-" + this._theme + " "+ level1.icon}></i>{level1.name}
                                </a>
                                <div class="page-nav-level page-nav-level-2">
                                    <a class="page-nav-level__back-btn">Back</a>
                                    <a class="page-nav-level-2__link--type-1" href={level1.url} id={"menu-"+level1.lvl1MenuNameEncoded+"-lvl1-"+level1.idStem+"-view-all"}>
                                        View All {level1.name}
                                    </a>
                                    {(level1.children && level1.children.length > 0) && (
                                        <div class="page-nav-level-2__main">
                                            <ul class={level1.features ? "page-nav-level-2__ul--type-1 page-nav-feature-tiles" : "page-nav-level-2__ul--type-1"}>
                                                {level1.children.map(level2 =>
                                                    <li class="page-nav-level-2__li--type-1">
                                                        <a href={level2.url} class={`page-nav-link page-nav-level-2__heading ${(level2.children && level2.children.length > 0) && 'has-children'}`} id={"menu-"+level2.lvl1MenuNameEncoded+"-lvl2-"+level2.idStem+"-view-all"} title={`View all ${level2.name}`}>
                                                            <i class={"icon-" + this._theme + " "+ level1.icon}></i>{level2.name}
                                                        </a>
                                                        {(level2.children && level2.children.length > 0) && (
                                                            <div class="page-nav-level page-nav-level-3">
                                                                <a class="page-nav-level__back-btn">Back</a>
                                                                <ul class="page-nav-level-3__ul--type-1">
                                                                    <li class="page-nav-level-3__li--type-1">
                                                                        <a class="page-nav-level-2__link--type-1" href={level2.url} id={"menu-"+level2.lvl2MenuNameEncoded+"-lvl2-"+level2.idStem+"-view-all"}>
                                                                            View All {level2.name}
                                                                        </a>
                                                                    </li>
                                                                    {level2.children.map(level3 =>
                                                                        <li class="page-nav-level-3__li--type-1">
                                                                            <a href={level3.url} class="page-nav-link" id={"menu-"+level3.lvl1MenuNameEncoded+"-lvl3-"+level3.idStem}>
                                                                                <i class={"icon-" + this._theme + " "+ level1.icon}></i>{level3.name}
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </li>
                                                )}
                                            </ul>
                                            {level1.features && (
                                                <div class="page-nav-feature-tiles-wrapper">
                                                    {level1.features.map(feature =>
                                                        <div class="page-nav-feature-tiles__item">
                                                            <a class="page-nav-feature-tiles__item-link" href={feature.url}>
                                                                <img src={feature.image} alt={feature.name}/>
                                                                <span class="page-nav-feature-tiles__item-heading">
                                                                    {feature.name}
                                                                </span>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, MegaMenuContent);