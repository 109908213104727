import Bowser from "bowser/bundled";
import "./browser-message.scss";

import { h } from "../_common/jsx-dom.svg.js";

const TAG = "rg-browser-message";

class BrowserMessage extends HTMLElement {

    _ele;

    constructor() {
        super();
    }

    connectedCallback() {

        if(this.isInvalidBrowser()) {

            this._ele = (
                <div id="browser-message" class="alert alert-warning" role="alert">
                    <div class="content-container">

                            <p><strong>Attention! </strong>Your browser is out of date and may not fully support all features of this website. Learn how to <a href="http://browsehappy.com/" class="alert-link">Upgrade Your Browser</a> to ensure the best experience.</p>

                    </div>
                </div>
            );

            this.appendChild(this._ele);
        }
    }

    isInvalidBrowser() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const blacklist = {
            mobile: {
                safari: "<9"
            },
            "android browser": "<=3.10",
            "internet explorer": "<=11",
            safari: "<=10.1",
            chrome: "<=20",
            firefox: "<=31",
            opera: "<22"
        };

        return browser.satisfies(blacklist);
    }
}

customElements.define(TAG, BrowserMessage);