import jsCookie from "js-cookie";

const COOKIE_KEY = "reece-product-compare";
const COOKIE_DURATION_IN_DAYS = 365;

const getWishlist = () =>  (jsCookie.get(COOKIE_KEY) || "").split(",")
  .filter(product => product && product.trim());

var isProductInWishlist = function(product, wishlist) {
  if (!wishlist) {
    wishlist = getWishlist();
  }
  for (var i = 0; i < wishlist.length; i++) {
    if (wishlist[i] == product) {
      return true;
    }
  }
  return false;
};

var removeProductFromWishlist = function(product, wishlist) {
  const index = wishlist.indexOf(product);

  if (index !== -1) {
    wishlist.splice(index, 1);
  }

  return wishlist;
};


var updateProductInWishlist = function(productCode) {
  var wishlist = getWishlist();
  if (isProductInWishlist(productCode, wishlist) == false) {
    wishlist.push(productCode);
  } else {
    wishlist = removeProductFromWishlist(productCode, wishlist);
  }
  jsCookie.set(COOKIE_KEY, wishlist.toString(), { expires: COOKIE_DURATION_IN_DAYS });
  updateWishlistCount();
};

var updateWishlistCount = function() {
  var wishlistHolder = document.getElementById("retail-wishlist-count-holder");
  if (wishlistHolder) {
    var wishlist = getWishlist();
    if (wishlist) {
      wishlistHolder.innerHTML = parseInt(wishlist.length);
    }
  }
};

var exp = {
  updateWishlistCount,
  isProductInWishlist,
  updateProductInWishlist
};

export function init() {
  window.reece.wishlist = exp;
  updateWishlistCount();
}
