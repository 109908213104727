import { Accounts } from "../../accounts/accounts";
import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "rg-header-accounts";

export class HeaderAccounts extends HTMLElement {

    _ele;
    _userData;

    constructor(userData) {
        super();
        this._userData = userData;
    }

    connectedCallback() {

        const accountChangeOptions = new Accounts(this._userData).transform();

        this._ele = (
            <li id="link-account-2-desktop" class="header-top-right-nav__li">
                {accountChangeOptions && accountChangeOptions.showAccountChangeList && ([
                    <a class="header-top-right-nav__link header-top-right-nav__icon--register" id="MaxCustomerAccount">{accountChangeOptions.activeAccount.customerName} ({accountChangeOptions.activeAccount.customerNumber})</a>,
                    <div class="header-top-right-nav__sub-nav" id="CustomerAccountList">
                        <span class="header-top-right-nav__sub-nav-heading">Switch maX view to:</span>
                        {accountChangeOptions.accountChangeListOptions.map((accountChangeListOption) => {
                            return (
                                <a class={"header-top-right-nav__sub-nav-link account-list-item " + accountChangeListOption.accountTypeStyleClass + " " + accountChangeListOption.activeStyleClass} href="/max" data-customer-number={accountChangeListOption.customerNumber}>
                                    {accountChangeListOption.customerName}
                                    <span>{accountChangeListOption.isParentAccount ? `Parent` : accountChangeListOption.isChildAccount ? `Child` : ""} Account No: {accountChangeListOption.customerNumber}</span>
                                </a>
                            )
                        })}
                        {accountChangeOptions.showAccountChangeLink && (
                            <a class="header-top-right-nav__sub-nav-link header-top-right-nav__sub-nav-link--all" href="/max/user-profile/trade-accounts" id="ListMoreAccounts">
                                See my other accounts
                            </a>
                        )}
                    </div>
                ])}
                {accountChangeOptions && !accountChangeOptions.showAccountChangeList && (
                    <a href="#" class="header-top-right-nav__link header-top-right-nav__icon--register" id="MaxCustomerAccount">{accountChangeOptions.activeAccount.customerName} ({accountChangeOptions.activeAccount.customerNumber}) </a>
                )}
            </li>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderAccounts);
