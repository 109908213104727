import "./header-top.scss";
import { HeaderTopNav } from "../header-top-nav/header-top-nav";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-header-top";

export class HeaderTop extends HTMLElement {

    _ele;
    _data;
    _userData;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        let topNav = new HeaderTopNav(this._userData, this._data);

        this._ele = (
            <div class="page-header-top-wrapper">
                {topNav}
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderTop);
