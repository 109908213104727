import { HeaderTopUser } from "../header-top-user/header-top-user";
import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "rg-header-top-nav";

export class HeaderTopNav extends HTMLElement {

    _ele;
    _data;
    _userData;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        let headerUser = new HeaderTopUser(this._userData, this._data);

        this._ele = (
            <div class="page-header__top">
                <nav>
                    {this._data.navLinks.map((link) => {
                        return (
                            <a
                                class="header-top-left-nav__link"
                                href={link.href}
                                {...link.target ? {target: link.target} : {}}
                            >
                                {link.text}
                            </a>
                        )
                    })}
                </nav>
                {headerUser}
            </div>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderTopNav);
