import "./site-message.scss";
import { reportError } from "../scripts/error-reporting";

import { h } from "../_common/jsx-dom.svg.js";

const TAG = "rg-site-message";

class SiteMessage extends HTMLElement {

    _ele;
    _message = {
        name: "",
        message: ""
    };
    _class = "hidden";

    constructor() {
        super();
    }

    connectedCallback() {
        this._initSiteMessage();
    }

    async _initSiteMessage() {
        try {
            const resp = await fetch(`/site/messages`);
            if (resp.status === 200) {
                const data = await resp.json();
                this._processMessages(data);
            }
            else if(resp.status < 200 || resp.status >= 300) {
                throw Error("Site message service unavailable");
            }
        }
        catch (e) {
            reportError("Site message service unavailable");
        }
    }

    _processMessages(data) {
        if (typeof data === "object") {
            let uris = this._getUris();
            let match = false;
            uris.forEach(uri => {
                data.forEach(m => {
                    if (!match && String(m.uri) === String(uri)) {
                        match = true;
                        this._message.name = m.name;
                        this._message.message = m.message;
                        if(m.isinformation) this._class = "alert alert-success";
                        if(m.iswarning) this._class = "alert alert-warning";
                        if(m.iserror) this._class = "alert alert-danger";
                    }
                });
            });
        }

        this._ele = (
            <div id="site-message" class={this._class}>
                <button type="button" class="close site-message-close" onClick={this._close} data-dismiss="alert">×</button>
                <div class="content-container">
                    <b>{this._message.name}</b>
                    <p innerHTML={this._message.message}></p>
                </div>
            </div>
        );

        this.appendChild(this._ele);
    }

    _close() {
        this.parentElement.style.display = 'none';
    }

    _getUris() {
        let i;
        let uris = ["/"];
        let urlSegments = window.location.pathname.split("/");

        for (i = 1; i < urlSegments.length; i++) {
            uris[i] = uris[i - 1] + urlSegments[i] + "/";
        }

        return uris.reverse();
    }
}

customElements.define(TAG, SiteMessage);
