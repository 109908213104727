import { MegaMenuData } from "./mega-menu-data";
import { HeaderMegaMenuNavigation } from "./mega-menu-navigation/mega-menu-navigation"
import { reportError } from "../scripts/error-reporting";

import { h } from "../_common/jsx-dom.svg.js";

const TAG = "mega-menu";

export class HeaderMegaMenu extends HTMLElement {

    _ele;
    _menu;
    _breakpoint = window.matchMedia('(min-width: 1200px)');
    _userData;
    _theme;

    constructor(userData, theme) {
        super();
        this._userData = userData;
        this._theme = theme;
    }

    connectedCallback() {

        (async() => {


            try {
                const megaMenuData = new MegaMenuData();
                const menu = await fetch("/site/menu");
                this._menu = await menu.json();
                this._menu = await megaMenuData.parseSiteMenu(this._menu, this._userData);

                let menus = document.createElement('ul');
                menus.classList.add('page-nav-level-0__ul');

                this._menu.map(i => {
                    let m = new HeaderMegaMenuNavigation(i, this._theme);
                    menus.appendChild(m);
                });

                this._ele = (
                    <div class="page-nav-level page-nav-level-0">
                        {menus}
                    </div>
                );

                this.appendChild(this._ele);
                this.init();
            }
            catch(e) {
                reportError("Mega menu service unavailable");
            }
        })();
    }

    init() {

        // Menu open / close
        let pageNav  = document.getElementById('page-nav'),
            navOpen  = document.getElementById('page-nav-open');

        // sanity checks
        if (pageNav === null || pageNav === undefined)  return;
        if (navOpen === null || navOpen === undefined) return;

        navOpen.addEventListener('click', function() {
            pageNav.classList.add('active');
            document.getElementsByTagName('body')[0].classList.add('overlay-active');
        }, true);
        this.handleMobileNavCloseAction();

        // keep an eye on viewport size changes
        this._breakpoint.addListener(this.handleResize());
        this.observerAndCall([].slice.call(document.querySelectorAll('[data-client-render-menu]')), this.handleResize);
        this.handleResize();

        if(typeof window.__reece_render_header__ === 'function') {
            //has client side user menu rendering
            this.observerAndCall([document.querySelector('[data-reece-mustache-template-id="mobile-user-nav"]')], function() {
                this.handleAccountDropdown();
                this.handleMobileNavCloseAction();
            });
        }
        this.handleAccountDropdown();
    }

    observerAndCall(eles, callback) {
        eles.forEach(ele => {
            var observer = new MutationObserver(callback);
            observer.observe(ele, {
                childList: true
            });
        })
    }

    handleMobileNavCloseAction() {
        var pageNav  = document.getElementById('page-nav');
        var navClose = document.getElementById('page-nav-close');
        if (pageNav === null || pageNav === undefined)  return;
        if (navClose === null || navClose === undefined) return;
        navClose.addEventListener('click', function() {
            pageNav.classList.remove('active');
            document.getElementsByTagName('body')[0].classList.remove('overlay-active');
        }, true);
    }

    // Function handle navigation resize
    handleResize() {
        var nav = document.querySelector('.page-nav-main');
        var navLevel = document.querySelectorAll('.page-nav-level');
        var navLevelBack  = document.querySelectorAll('.page-nav-level__back-btn');
        var navLevelItems = document.querySelectorAll('.page-nav-link');

        var navLevel1UL = document.querySelectorAll('.page-nav-level-1__ul--type-1');
        var navLevel0Items = document.querySelectorAll('.page-nav-level-0__li');
        var navLevel1Items = document.querySelectorAll('.page-nav-level-1__li--type-1');
        //var navLevel1 = document.querySelectorAll('.page-nav-level-1');
        var navLevel2 = document.querySelectorAll('.page-nav-level-2');

        // Reset page navigation height, active state
        document.querySelector('.page-nav-main').style.height = 'auto';
        navLevel.forEach(function(nav) {
            nav.classList.remove('active');
        });
        navLevel1Items.forEach(function(nav) {
            nav.classList.remove('active');
        });

        // For larger screen size
        if (this._breakpoint.matches === true) {
            // Disable slider
            // Remove onclick event from mobile
            if(navLevelItems !== null) {
                navLevelItems.forEach(function(element) {
                    element.onclick = null;
                });
            }
            if(navLevelBack !== null) {
                navLevelBack.forEach(function(element) {
                    element.onclick = null;
                });
            }

            if(navLevel0Items !== null) {
                // Base level navigation
                navLevel0Items.forEach(function(element0) {
                    var currentSubNavItems = element0.querySelectorAll('.page-nav-level-1__li--type-1');

                    // Set first level 1 navigation open as default when loaded
                    if(element0.querySelector('.page-nav-level-1__li--type-1') !== null) {
                        element0.querySelector('.page-nav-level-1__li--type-1').classList.add('active');
                    }
                    if(element0.querySelector('.page-nav-level-2') !== null) {
                        element0.querySelector('.page-nav-level-2').classList.add('active');
                    }

                    var currentLevel0TimeoutHandler = null;

                    // Add mouse enter event for base level navigation
                    element0.onmouseenter = function onLevel0ItemOpen(event) {
                        var target = event.currentTarget;
                        clearTimeout(currentLevel0TimeoutHandler);
                        currentLevel0TimeoutHandler = setTimeout(() => expandLevel0ItemMenu(target), 100);
                    }

                    function expandLevel0ItemMenu(item) {
                        var currentSubNav1 = item.querySelector('.page-nav-level-1');
                        var currentSubNav2 = item.querySelector('.page-nav-level-2');
                        var currentSubNav1Ul = item.querySelector('.page-nav-level-1__ul--type-1');

                        // Remove active class from other item
                        navLevel0Items.forEach(function(elem) {
                            elem.classList.remove('active');
                        });
                        // Add active class to current menu item and submenu
                        item.classList.add('active');
                        if(currentSubNav1 !== null) {
                            currentSubNav1.classList.add('active');
                        }

                        // Init the navheight on first load
                        if(currentSubNav1Ul !== null && currentSubNav2 !== null) {
                            var navHeight1Num = (currentSubNav1Ul !== null) ? _getTotalHeightOfChildren(currentSubNav1Ul) : 300;
                            var navHeight1 = (currentSubNav1Ul !== null) ? _getTotalHeightOfChildren(currentSubNav1Ul) + 'px' : 'auto';
                            var navHeight2Num = (currentSubNav2 !== null) ? currentSubNav2.offsetHeight : 300; //some magic number, should not matter though
                            var navHeight2 = (currentSubNav2 !== null) ? currentSubNav2.offsetHeight + 'px' : 'auto';
                            if(navHeight1Num >= navHeight2Num) {
                                currentSubNav1Ul.style.height = navHeight1;
                                currentSubNav2.style.height = navHeight1;
                            }
                            else {
                                currentSubNav1Ul.style.height = navHeight2;
                                currentSubNav2.style.height = navHeight2;
                            }
                        }
                    }

                    // Add mouse leave event for base level navigation
                    element0.onmouseleave = function (event) {
                        clearTimeout(currentLevel0TimeoutHandler);
                        var item = event.currentTarget;
                        var currentSubNav1 = item.querySelector('.page-nav-level-1');
                        // Remove active class from other item
                        navLevel0Items.forEach(function(elem) {
                            elem.classList.remove('active');
                        });
                        if(currentSubNav1 !== null) {
                            currentSubNav1.classList.remove('active');
                        }
                    };

                    if(currentSubNavItems !== null) {
                        currentSubNavItems.forEach(function(element1) {
                            element1.onmouseenter = function (event) {
                                var item1 = event.currentTarget;
                                var currentSubNav1UL = _findAncestor(item1, 'page-nav-level-1__ul--type-1');
                                var currentSubNav2 = currentSubNav1UL.querySelectorAll('.page-nav-level-2');
                                var currentSubNav2Item = item1.querySelector('.page-nav-level-2');
                                var navHeight1Num = (currentSubNav1UL !== null) ? _getTotalHeightOfChildren(currentSubNav1UL) : 300;
                                var navHeight1 = (currentSubNav1UL !== null) ? _getTotalHeightOfChildren(currentSubNav1UL) + 'px' : 'auto';
                                var navHeight2Num = (currentSubNav2Item !== null) ? currentSubNav2Item.offsetHeight : 300;
                                var navHeight2 = (currentSubNav2Item !== null) ? currentSubNav2Item.offsetHeight + 'px' : 'auto';
                                // If no sub nav reset nav height
                                if(currentSubNav1UL !== null && navHeight2 == 'auto') {
                                    currentSubNav1UL.style.height = navHeight2;
                                }
                                if(currentSubNav1UL !== null && currentSubNav2Item !== null) {
                                    if(navHeight1Num >= navHeight2Num) {
                                        currentSubNav1UL.style.height = navHeight1;
                                        currentSubNav2Item.style.height = navHeight1;
                                    }
                                    else {
                                        currentSubNav1UL.style.height = navHeight2;
                                        currentSubNav2Item.style.height = navHeight2;
                                    }
                                }

                                // Remove active class from other item
                                currentSubNavItems.forEach(function(elem) {
                                    elem.classList.remove('active');
                                });
                                // Remove active class from all level 2 nav
                                currentSubNav2.forEach(function(elem) {
                                    elem.classList.remove('active');
                                });
                                // Add active class to current menu submenu
                                item1.classList.add('active');
                                if(currentSubNav2Item !== null) {
                                    currentSubNav2Item.classList.add('active');
                                }
                            };
                        });
                    }//end if
                });//end foreach
            }//end if(navLevel0Items !== null)
        }//end if breakpoint.matches
        // For smaller screen size
        else if (this._breakpoint.matches === false) {
            // Reset navigation height
            if(navLevel1UL !== null) {
                navLevel1UL.forEach(function(element) {
                    element.style.height = 'auto';
                });
            }
            if(navLevel2 !== null) {
                navLevel2.forEach(function(element) {
                    element.style.height = 'auto';
                });
            }

            // Remove mouse hover event from desktop
            if(navLevel0Items !== null) {
                navLevel0Items.forEach(function(element) {
                    element.onmouseenter = null;
                    element.onmouseleave = null;
                });
            }
            if(navLevel1Items !== null) {
                navLevel1Items.forEach(function(element) {
                    element.onmouseenter = null;
                    element.onmouseleave = null;
                });
            }

            // Handle user click on menu item on each level
            if(navLevelItems !== null) {
                navLevelItems.forEach(function(element) {
                    element.onclick = function (event) {

                        if(event.currentTarget && event.currentTarget.id === 'Max-Megamenu') {
                            //disable click and open url in max mega menu
                            event.preventDefault();
                        }

                        if(event.currentTarget.classList.contains("has-children")) {
                            //disable click if it has children
                            event.preventDefault();
                        }

                        var item = event.currentTarget;
                        var navLevelHeight = item.parentElement.querySelector('.page-nav-level');
                        if(navLevelHeight !== null) {
                            // Set nav level height
                            nav.style.height = navLevelHeight.offsetHeight + 'px';
                            // Add acitve class to the nav level
                            item.parentElement.querySelector('.page-nav-level').classList.add('active');
                        }
                    };
                });
            }

            // Handle user click on back btn on mobile
            if(navLevelBack !== null) {
                navLevelBack.forEach(function(element) {
                    element.onclick = function (event) {
                        var item = event.currentTarget;
                        var navLevelHeight = _findAncestor(_findAncestor(item, 'page-nav-level'), 'page-nav-level').offsetHeight;
                        // Set nav level height
                        nav.style.height = navLevelHeight + "px";
                        // Remove active class from the nav level
                        _findAncestor(item, 'page-nav-level').classList.remove('active');
                    };
                });
            }
        }//end of else breakpoint.match

        // Get total height of an element which contains children element. e.g. UL
        var _getTotalHeightOfChildren = function (el) {
            var totalHeight = 0;
            el.childNodes.forEach(function(elem) {
                if(!isNaN(elem.offsetHeight)) {
                    totalHeight += elem.offsetHeight;
                }
            });
            return totalHeight;
        };

        // Find parent element.
        function _findAncestor(el, cls) {
            while ((el = el.parentElement) && !el.classList.contains(cls));
            return el;
        }
    }

    // Function handle mobile navigation account dropdown
    handleAccountDropdown() {
        // Account drop down
        var initalNavTopHeight = 0;
        var navTop = document.querySelector('.page-nav-top');
        var navTopP1 = document.querySelector('.page-nav-top__group');
        var accountNavs = document.querySelectorAll('.page-nav-top__link-group-li');
        var accountSubNavs = document.querySelectorAll('.page-nav-top__link-sub-group');
        // This contains both desktop and mobile account nav item.
        var accountList = document.querySelectorAll('.account-list-item');

        if(navTop !== null) {
            // Get height of the display none element
            navTop.style.height = null; //IMPORTANT!!! RESETING height so that it can be called multiple times
            navTop.classList.add('active');
            initalNavTopHeight = navTop.offsetHeight;
            navTop.classList.remove('active');
            // Set inital height for mobile nav top part
            navTop.style.height = initalNavTopHeight + 'px';
        }

        if(accountNavs !== null) {
            accountNavs.forEach(function(element) {
                const pageNavTopLink = element.querySelector('.page-nav-top__link');
                pageNavTopLink && ( pageNavTopLink.onclick = function (event) {
                    var item = event.currentTarget.parentElement.parentElement.parentElement;
                    var accountSubNav = item.querySelector('.page-nav-top__link-sub-group');

                    // Check if there is sub navigation
                    if(accountSubNav !== null) {
                        // Check if the menu is active
                        if (item.classList.contains('active')) {
                            item.classList.remove('active');
                            // Remove active class after centain timeframe so there won't be a blank space appear when slide toggle
                            window.setTimeout(function () {
                                accountSubNav.classList.remove('active');
                            }, 300);
                            // Set navTop height to inital height
                            navTop.style.height = initalNavTopHeight + 'px';
                        }
                        else {
                            // Remove all active state for all elements
                            accountNavs.forEach(function(elem) {
                                elem.classList.remove('active');
                            });
                            accountSubNavs.forEach(function(elem) {
                                elem.classList.remove('active');
                            });

                            item.classList.add('active');
                            accountSubNav.classList.add('active');

                            var expandHeight = 0;
                            // Total height of the account subnav (light grey section)
                            accountSubNav.childNodes.forEach(function(el) {
                                if(!isNaN(el.offsetHeight)) {
                                    expandHeight += el.offsetHeight;
                                }
                            });

                            // Set navTop height
                            navTop.style.height = navTopP1.offsetHeight + expandHeight + 'px';
                            accountSubNav.style.height = expandHeight + 'px';
                        }
                    }
                    else {
                        // Remove all active state for all elements
                        accountNavs.forEach(function(elem) {
                            elem.classList.remove('active');
                        });
                        navTop.style.height = initalNavTopHeight + 'px';
                    }
                });
            });
        }//end if(accountNavs) !== null

        // Check for both desktop and mobile
        if(accountList !== null) {
            accountList.forEach(function(element) {
                element.onclick = function (event) {
                    // Remove all active class in same level
                    accountList.forEach(function(ele) {
                        if(ele.parentNode)
                            ele.classList.remove('active');
                    });
                    // Add active to clicked item
                    event.currentTarget.classList.add('active');
                };
            });
        }
    }



    // https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/
    _getClosest(elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function(s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    //while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }

        // Get the closest matching element
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) return elem;
        }
        return null;
    }
}

customElements.define(TAG, HeaderMegaMenu);
