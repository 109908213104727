import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "feature-menu-content";

export class FeatureMenuContent extends HTMLElement {

    _ele;
    _data;

    constructor(data) {
        super();
        this._data = data;
    }

    connectedCallback() {

        this._ele = (
            <li class="page-nav-level-0__li">
                <a id={this._data.id} class="page-nav-link" {... this._data.url ? {href: this._data.url} : {}}>{this._data.name}</a>
                <div class="page-nav-level page-nav-level-1">
                    <a class="page-nav-level__back-btn">Back</a>
                    { this._data.header && <a class="page-nav-level__nav-header" href={this._data.header.url} innerHTML={this._data.header.name} /> }
                    <ul class="page-nav-level-1__ul--type-4">
                        {this._data.children.map(d =>
                            <li
                                id={d.id}
                                class={ d.feature ? "page-nav-level-1__li--type-4 page-nav-level-1__li-last--type-4" : "page-nav-level-1__li--type-4" }
                                >
                                <a href={d.url}>
                                    {d.image && (
                                        <span class="page-nav-level-1__img--type-4">
                                            <img src={d.image} />
                                        </span>
                                    )}
                                    <span class="page-nav-level-1__content--type-4">
                                        {d.label && (
                                            <span class="page-nav-level-1__label--type-4">{d.label}</span>
                                        )}
                                        <span class="page-nav-level-1__title--type-4">{d.name}</span>
                                        {d.description && (
                                            <span class="page-nav-level-1__desc--type-4">{d.description}</span>
                                        )}
                                    </span>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        );

        this.classList.add("page-nav-level-0__li");
        this.appendChild(this._ele);
    }
}

customElements.define(TAG, FeatureMenuContent);
