import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "icon-menu-content-child-element";

export class IconMenuContentChildElement extends HTMLElement {

    _ele;
    _data;
    _theme;

    constructor(data, theme) {
        super();
        this._data = data;
        this._theme = theme;
    }

    connectedCallback() {

        let linkContent = ([
            <span class="page-nav-level-1__icon--type-3">
                <i class={"icon-"+this._theme+" "+this._data.icon} {... this._data.iconSvg ? {innerHTML: this._data.iconSvg} : {}} />
            </span>,
            <span class="page-nav-level-1__title--type-3">{this._data.name}</span>,
            <span class="page-nav-level-1__desc--type-3">{this._data.description}</span>
        ]);

        if(this._data.visible) {
            this._ele = (
                <li id={this._data.id} class={this._data.disabled ? "page-nav-level-1__li--type-3 disabled" : "page-nav-level-1__li--type-3" }>
                    {this._data.disabled ? (
                        <a>
                            {linkContent}
                        </a>
                    ):(
                        <a href={this._data.url}>
                            {linkContent}
                        </a>
                    )}
                </li>
            );

            this._data.disabled ?
                this.classList.add(["page-nav-level-1__li--type-3", "disabled"]) :
                this.classList.add('page-nav-level-1__li--type-3');

            this.appendChild(this._ele);
        }
    }


}

customElements.define(TAG, IconMenuContentChildElement);
