import { HeaderAccounts } from "../accounts/accounts";
import { h } from "../../../_common/jsx-dom.svg.js";

const TAG = "rg-header-logged-in";

export class LoggedIn extends HTMLElement {

    _ele;
    _data;
    _userData;

    constructor(userData, data) {
        super();
        this._userData = userData;
        this._data = data;
    }

    connectedCallback() {

        let accounts;

        if (this._userData && this._userData.isMaxUser){
            accounts = new HeaderAccounts(this._userData);
        }

        this._ele = (
            <span class="header-top-right-nav__span">
                <li id="link-account-1-desktop" class="header-top-right-nav__li">
                    <a class="header-top-right-nav__link header-top-right-nav__icon--login" id="LoggedInUserName">{this._userData.firstName}</a>
                    <div class="header-top-right-nav__sub-nav">
                        {this._userData.isMaxUser && (
                            <a href="/max" class="header-top-right-nav__sub-nav-link" id="AccountHome">Account home</a>
                        )}
                        {!this._userData.isMaxUser && this._data.retailUserLinks.map((link) => {
                            return (
                                <a href={link.href} id={link.id} class="header-top-right-nav__sub-nav-link">{link.text}</a>
                            )
                        })}
                        <a href="/max/user-profile/" class="header-top-right-nav__sub-nav-link" id="ManageAccount">Account settings</a>
                        <a href="/account/logout" class="header-top-right-nav__sub-nav-link" id="Logout">Logout</a>
                    </div>
                </li>
                {this._userData.isMaxUser && (
                    <span>
                        {accounts}
                    </span>
                )}
            </span>
        );

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, LoggedIn);
