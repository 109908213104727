import { ArticleMenuContent } from "./article-menu-navigation/article-menu-navigation";
import { FeatureMenuContent } from "./feature-menu-content/feature-menu-content";
import { IconMenuContent } from "./icon-menu-content/icon-menu-content";
import { MegaMenuContent } from  "./mega-menu-content/mega-menu-content";

import { h } from "../../_common/jsx-dom.svg.js";

const TAG = "mega-menu-navigation";

export class HeaderMegaMenuNavigation extends HTMLElement {

    _ele;
    _menu;
    _theme;

    constructor(menu, theme) {
        super();
        this._menu = menu;
        this._theme = theme;
    }

    connectedCallback() {

        let menu;
        this._menu.isMegaMenu ? menu = new MegaMenuContent(this._menu.data, this._theme) :
            this._menu.isContentMenu ? menu = new FeatureMenuContent(this._menu.data) :
            this._menu.isToolMenu ? menu = new IconMenuContent(this._menu.data, this._theme) :
            this._menu.isArticleMenu ? menu = new ArticleMenuContent(this._menu.data) : menu = new MegaMenuContent(this._menu.data);

        this._ele = menu;

        this.appendChild(this._ele);
    }
}

customElements.define(TAG, HeaderMegaMenuNavigation);
