(function () {
    const THEME_OVERRIDE_COOKIE = "theme-override";

    var setMaxLiteClass = function() {
        var maxLiteThemeCookie = getCookie(THEME_OVERRIDE_COOKIE);

        if(!maxLiteThemeCookie || maxLiteThemeCookie != 'MAX-LITE') {
            return false;
        }

        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = `
        #page-header .page-header-main-wrapper .page-header__main .header-main__icons .header-main__icon--fav, 
        #page-header .page-header-top-wrapper,
        #page-header #page-nav.page-nav-wrapper,
        #page-header .page-header-main-wrapper .page-header__main .header-main__icon--menu,
        #page-footer .page-footer__main .footer-sitemap .footer-sitemap__mid{
            display: none !important;
        } 
        #page-header .page-header-main-wrapper .page-header__main .reece-logo-wrapper a{
            pointer-events: none !important;cursor: default !important;text-decoration: none !important;
        } 
        .product-listing__feature-banner {
            display: none !important;
        }
        .page-wrapper{
            padding-top: 200px !important;
        }
        @media (min-width:768px){ 
            .page-wrapper{
                padding-top: 114px !important;
            }
        } 
        #page-footer .page-footer__payment-info .footer-payment-info .footer-payment-info__left{
            visibility: hidden !important;
        }
        .login-page .info-block-account,
        .login-page .info-block-contact,
        .login-page .info-block-shopping {
            display: none !important;
        }
        .account #page-header .header-main__search-wrapper {
            display: none !important;
        }
        .account #page-header .header-main__icons .header-main__icon--cart {
            display: none !important;
        }
        .account #forgotpassword {
            display: none !important;
        }`;
        document.head.appendChild(css);

        return true;
    };

    var getCookie = function(cookiename) {
        var name = cookiename + "=";
        var cookies = document.cookie.split(';');
        for(var i=0; i<cookies.length; i++){
            var trimmed = cookies[i].trim();
            if (trimmed.indexOf(name)==0) {
                var encodedValue = trimmed.substring(name.length,trimmed.length);
                return decodeURIComponent(encodedValue);
            }
        }
        return undefined;
    };

    setMaxLiteClass();
})();
